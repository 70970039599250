const app = {
  state: {
    loadingRequest: false
  },
  mutations: {
    SET_LOADING_REQUEST(state, data) {
      state.loadingRequest = data
    },
  },
  actions: {
    async setLoading({ commit }, data) {
      commit('SET_LOADING_REQUEST', data)
    },
  }
}

export default app
