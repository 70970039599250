import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VConsole from 'vconsole'
import VueClipboard from 'vue-clipboard2'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant)

import './style/index.scss'
// rem
import 'amfe-flexible'

if (['test', 'dev', 'uat'].includes(process.env.VUE_APP_TITLE)) {
  Vue.use(new VConsole())
}

Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
