import Vue from 'vue'
import VueRouter from 'vue-router'

import Share from './share'
import Privacy from './privacy'
import Activity from './activity'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/entrance'
    // component: () => import('@/views/index')
  },
  {
    path: '/entrance',
    name: 'entrance',
    component: () => import('@/views/entrance')
  },
  ...Share,
  ...Privacy,
  ...Activity,
  {
    path: '*',
    name: '404',
    component: () => import('@/views/error')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router