export default [
  {
    path: '/share/personainfo/:id',
    name: 'PersonaInfo',
    component: () => import('@/views/share/personaInfo')
  },
  {
    path: '/share/trends/:id',
    component: () => import('@/views/share/trends')
  },
  {
    path: '/share/news/:id',
    component: () => import('@/views/share/news')
  },
  {
    path: '/share/register/:code',
    component: () => import('@/views/share/register')
  },
  {
    path: '/share/video/:id',
    component: () => import('@/views/share/video')
  },
  {
    path: '/share/activity/detail',
    // meta: { title: '活动详情' },
    name: 'activityDetail',
    component: () => import('@/views/share/activity/detail')
  },
  {
    path: '/share/course/:id',
    meta: { title: '课程详情' },
    name: 'CourseDetail',
    component: () => import('@/views/share/course')
  },
  {
    path: '/share/rankings',
    meta: { title: '榜单' },
    name: 'Rankings',
    component: () => import('@/views/share/rankings')
  },
  {
    path: '/share/sponsor/:id',
    meta: { title: '赞助详情' },
    name: 'Sponsor',
    component: () => import('@/views/share/sponsor')
  },
]